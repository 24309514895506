<template>
    <div
        v-show="!isLoading"
        class="layout"
        :data-menu-open="menuIsOpen"
    >
        <!--  // fixme :style={layoutStyle} causes style hydration errors -->
        <ClientOnly>
            <NuxtLoadingIndicator :color="loadingColor" />
        </ClientOnly>

        <section class="layout__header">
            <TheNavigation
                :disable-login="disableLogin"
                @menu-toggled="onMenuToggle"
            />
        </section>

        <BaseNotice
            v-if="disableLogin && noticeETA"
            :message="
                `The login system is currently undergoing maintenance and is not available.${noticeETA ? ' It will be back online on ' + noticeETA + '.' : ''}`
            "
            :readmore-link="noticeLink"
        />

        <slot />

        <section class="layout__footer">
            <TheFooter />
        </section>
    </div>
</template>

<script setup>
import {setVariablesFromConfigObject} from '@digitalnatives/css-variables';
import {formats, formatSingleDate} from '~/utils/date';
import LoginQuery from '~/graphql/queries/global/Login.graphql';

const {activeSite} = useMultisite();
const loadingColor = ref('#CCC');
const isLoading = ref(true);
// const route = useRoute();

// Todo - move to the useSiteInjection within Pip.
useCookiePurger(1);

onBeforeMount(() => {
    isLoading.value = true;

    const group = activeSite.value?.group ?? activeSite.value?.handle;
    const rtl = activeSite.value?.rtl ?? false;

    import(`@/assets/theme/${group}/index.js`)
        .then(({default: theme}) => {
            loadingColor.value = theme.color.primary;
            if (rtl) {
                theme.direction = 'rtl';
            }
            setVariablesFromConfigObject(theme);
        })
        .catch(() => {
            loadingColor.value = '#FE6B20';
            console.warn(`Theme '${group}' not found, using default theme.`);
        });

    if (rtl) {
        document.documentElement.setAttribute('dir', 'rtl');
    }


    isLoading.value = false;
});

const menuIsOpen = ref(null);

function onMenuToggle(state) {
    menuIsOpen.value = state;
}

onMounted(() => {
    if (!navigator.serviceWorker?.getRegistrations()) {
        return;
    }
    navigator.serviceWorker.getRegistrations().then(async(registrations) => { // fixme: temp fix unregister invalid service workers
        if (registrations.length) {
            for (const registration of registrations) {
                await registration.unregister();
            }
            window.location.reload();
        }
    });
});

const {variables} = useCraftVariables();
delete variables.uri;
const {data: loginData} = await useLazyAsyncQuery({
    query: LoginQuery,
    variables
});

const disableLogin = computed(() => {
    return toValue(loginData)?.login?.hideLogin;
});

const noticeETA = computed(() => {
    const endOfDowntimeValue = toValue(loginData)?.login?.noticeEndDateOfDowntime;
    return endOfDowntimeValue ? formatSingleDate(endOfDowntimeValue, formats.dayMonth) : null;
});

const noticeLink = computed(() => {
    return toValue(loginData)?.login?.noticeReadMoreLink?.[0]?.uri;
});

// const layoutStyle = computed(() => ({
//     backgroundColor: route.path === '/' ? 'var(--home-bg-color)' : 'var(--color-lightest)'
// }));


/**
 methods: {
 onMenuToggle(state) {
 this.menuIsOpen = state;
 }
 }
 */
</script>

<style lang="less">
.layout {
    display: grid;
    grid-template-areas: 'header' 'notice' 'main' 'footer';
    grid-template-rows: auto auto 1fr auto;
    min-height: 100vh;
    gap: 0;
    margin: 0 auto;
    background: var(--color-lightest);
}

.layout[data-menu-open=true] {
    &:before {
        content: '';
        display: block;
        position: fixed;
        z-index: 10;
        background: var(--color-black-30);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @media @q-xl-min {
        overflow: auto;

        &:before {
            content: none;
            display: none;
        }
    }
}

.layout__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 100;
}

.layout__main {
    .grid-container();
    .vertical-padding();

    grid-area: main;
    justify-self: center;
}

.layout__main--narrow {
    .grid-container(true, var(--grid-maxWidth-content));
}

.layout__main--centered {
    align-content: center;
}

.layout__main--no-top-padding {
    padding-top: 0;

    .error:first-child {
        .vertical-padding();
    }
}

.layout__footer {
    grid-area: footer;
}
</style>
