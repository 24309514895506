import { useCookie } from '#app';

export const useCookiePurger = (purgeIndex) => {
    const purgeIndexCookie = useCookie('purge-index', { sameSite: 'lax' });
    const storedIndex = purgeIndexCookie.value || 0;

    if (storedIndex < purgeIndex) {
        const cookiesToClear = [
            'next-auth.callback-url',
            'next-auth.csrf-token',
            'next-auth.state',
            
            '__Secure-next-auth.callback-url',
            '__Secure-next-auth.session-token',
            '__Host-next-auth.csrf-token'
        ];

        cookiesToClear.forEach(cookieName => {
            const cookie = useCookie(cookieName, { sameSite: 'lax' });
            cookie.value = null;
        });

        // Bump purge index
        purgeIndexCookie.value = purgeIndex;
    }
};
